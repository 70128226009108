<script>
  import "@unocss/reset/eric-meyer.css";
  // @ts-ignore
  import { install } from "ga-gtag";

  import { dev } from "$app/environment";
  import "uno.css";
  import { onMount } from "svelte";

  onMount(() => {
    !dev && install("G-F8K8V9N5K4");
  });
</script>

<slot />

<svelte:head>
  {#if !dev}
    <script async src="https://umami.muspimerol.site/script.js" data-website-id="bb4dee2c-86e6-4b19-a2f5-37d38a9648b0"></script>
  {/if}
</svelte:head>

<style>
  :global(:root) {
    font-size: 0.835vw;
    --uno: <xl:\!text-[1.6vw];
  }

  :global(html) {
    --uno: bg-true-gray-950 text-true-gray-400 select-none whitespace-nowrap overflow-x-hidden;
  }

  :global(body) {
    --uno: m-0;
    letter-spacing: 0.2pt;
    line-height: 1.4 !important;
  }
  
  :global(a) {
    --uno: no-underline text-inherit;
  }
</style>
